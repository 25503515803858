@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

html,
body {
  overscroll-behavior: contain;
  overflow: hidden;
  font-family: "Bebas Neue", Arial, sans-serif;
  position: relative;
}

body.game {
  background-color: darkgray;
}

.bebasNeue {
  font-family: var(--font-bebas-neue);
}

*:focus {
  outline: none;
}

.global-shadow {
  margin: 2px;
  color: white;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
}

.checker {
  fill: black;
}

circle {
  cursor: grab;
}

circle.active {
  cursor: grabbing;
}

.draggable {
  position: absolute;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;

    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

@font-face {
  font-family: "farcaster";
  src:
    url("/fonts/farcaster.ttf?vpja1l") format("truetype"),
    url("/fonts/farcaster.woff?vpja1l") format("woff"),
    url("/fonts/farcaster.svg?vpja1l#farcaster") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.fc {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "farcaster" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: inline-block;
  text-rendering: auto;
}

.fc-square-farcaster:before {
  content: "\3a0";
}

.fc-farcaster:before {
  content: "\3c0";
}

[class^="fc-"],
[class*=" fc-"] {
  /* use !important to prevent issues with browser extensions that change fonts */

  display: inline-block;
  text-rendering: auto;
}
